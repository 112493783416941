<template>
  <e-modal
    :visible="visible"
    title="打印菜单"
    :width="800"
    okText="打印"
    @close="onClose"
    @ok="onPrint"
  >
    <div class="table" id="printMe">
      <table v-if="order">
        <tr>
          <td :colspan="dishs.length"><h1>五味楼菜单</h1></td>
        </tr>
        <tr>
          <td :colspan="dishs.length">
            <div class="info">
              <span>房间：{{order.tableName}}</span>
              <span>标准：{{order.comboPrice}}元/位 * {{order.peopleCount}}人</span>
              <span>{{$filters.formatDate(order.bookTime, 'MM月DD日')}} 午/晚</span>
            </div>
          </td>
        </tr>
        <tr>
          <td v-for="(v, k) in dishs" :key="k">
            <div class="dish-name">{{ v.dish.name }}</div>
          </td>
        </tr>
        <tr>
          <td :colspan="dishs.length">
            备注：如果您有任何食品过敏症状，请告知我们的工作人员
          </td>
        </tr>
      </table>
    </div>
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import form from '@/mixins/form'
import Print from '@/components/printer'

export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const title = ref('')
    const order = ref()
    const dishs = ref([])

    const onClose = () => {
      loading.value = false
      visible.value = false
    }

    const onPrint = () => {
      setTimeout(() => {
        // eslint-disable-next-line no-new
        new Print({
          ids: 'printMe',
          popTitle: '打印菜单',
          styles:
            '.dish-name{-webkit-writing-mode: vertical-rl;writing-mode: vertical-rl;margin: 0 auto;}',
          openCallback () {
            // 调用打印之后的回调事件
            console.log('打印开始')
          },
          closeCallback () {
            console.log('打印关闭')
          },
          beforeOpenCallback () {
            console.log('打印开始前')
          }
        })
      }, 100)
    }

    return {
      title,
      visible,
      loading,
      order,
      dishs,
      onClose,
      onPrint
    }
  },

  methods: {
    open (order) {
      this.order = order
      console.log(order.orderItems)
      this.dishs = order.orderItems.filter(x => x.type === 0 || x.type === 4)
      this.visible = true
    },

    vertical (words) {
      const wArr = words.split('')
      let res = ''
      wArr.map((e) => {
        res += '<div>' + e + '<div>'
      })
      return res
    }
  }
})
</script>

<style lang="less" scoped>
.table {
  overflow: auto;
  table {
    margin: 0 auto;
    tr {
      td {
        text-align: center;
        font-size: 20px;
        font-family: "宋体";
        border: 1px solid #ccc;
        padding: 10px;
        vertical-align: top;
        h1 {
          padding: 0;
          margin: 0;
          font-size: 26px;
        }

        .info {
          display: flex;
          justify-content: space-between;
        }

        .dish-name {
          writing-mode: vertical-rl;
          -webkit-writing-mode: vertical-rl;
          height: 300px;
          text-align: left;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
